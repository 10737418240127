@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
    --nav-bezier: cubic-bezier(0.08, 0.59, 0.96, 0.67);
  }
}

body {
  color: rgb(var(--foreground-rgb));
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
  background-color: #ffffea;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

::-moz-selection {
  color: white;
  background: #5923f9;
}

::selection {
  color: white;
  background: #5923f9;
}

#__next {
  min-height: 100vh;
}

button,
a,
img {
  user-select: none;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}

details summary::-webkit-details-marker {
  display: none;
}

.szh-accordion__item-heading,
.szh-accordion__item-btn,
.szh-accordion__item-content {
  width: 100%;
}

.drawer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  height: 2rem;
  background: linear-gradient(
    to top,
    transparent,
    rgb(var(--background-end-rgb))
  );
  z-index: 1;
  backdrop-filter: blur(1rem);
}

select {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
}

.select-wrapper::after {
  content: "▲";
  transform: rotate(180deg);
  font-size: 0.7rem;
  top: 30%;
  right: 15px;
  position: absolute;
}

.szh-accordion__item-btn {
  outline: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.paper-texture {
  background-image: url("/paper3.jpg");
  background-repeat: repeat;
  background-size: contain;
  position: relative;
  z-index: 1;
}

.paper-texture::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #4517c0, #3a159d);
  width: 100%;
  height: 100%;
  z-index: -1;
  mix-blend-mode: color-dodge;
}

.blue-glow {
  box-shadow: 0 0 30px 0 #5923f9;
}

.add-exercise-tooltip::after {
  content: "";
  position: absolute;
  bottom: -1.42em;
  left: calc(50% - 17px);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
  z-index: -1;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359.9deg);
  }
}

.tremor-BarList-bars .bg-opacity-30 {
  --tw-bg-opacity: 1;
}

.home-hide-up {
  opacity: 0;
  /* filter: blur(5px); */
  transform: translateY(50px);
  transition: all 0.5s ease-in-out;
}

.home-show-up {
  opacity: 1;
  /* filter: blur(0); */
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
}

.home-hide-down {
  opacity: 0;
  /* filter: blur(5px); */
  transform: translateY(-50px);
  transition: all 0.5s ease-in-out;
}

.home-show-down {
  opacity: 1;
  /* filter: blur(0); */
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
}

.recharts_measurement_span {
  display: none !important;
}

.total-weight-over-time .recharts-surface {
  width: 105% !important;
}

.divider {
  display: flex;
  align-items: center;
}

.divider::before,
.divider::after {
  flex: 1;
  content: "";
  padding: 1px;
  @apply bg-zinc-600;
  margin: 5px;
}

.tremor-BarList-root
  .text-right
  .tremor-BarList-labelWrapper
  .tremor-BarList-labelText {
  color: #000 !important;
}

.tremor-BarList-barText {
  color: #000 !important;
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in-up {
  animation: fadeInUp 0.5s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@layer components {
  input[type="range"] {
    @apply appearance-none bg-transparent;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    @apply bg-black/25 rounded-full;
  }

  input[type="range"]::-moz-range-track {
    @apply bg-black/25 rounded-full;
  }

  input[type="range"]::-ms-track {
    @apply bg-black/25 rounded-full;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply appearance-none w-4 h-4 bg-spring-green-600 rounded-full;
    transform: scale(1.5);
  }
}
